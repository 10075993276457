<template>
  <div class="card p-4 is-shadowless">
    <h1 class="is-size-5 mb-5">
      Settings
    </h1>
    <b-field label="Application Name*"
      :type="applicationErrors.name ? 'is-danger' : ''"
      :message="applicationErrors.name ? applicationErrors.name : ''">

      <b-input v-model="application.name"
        @keyup.native="validateApplication('name')"
      />
    </b-field>
    <b-field label="Application Description">
      <b-input
        v-model="application.description"
        type="textarea"
      />
    </b-field>
    <b-field
      label="Collaborators"
      class="pb-6"
      :type="applicationErrors.shared_with ? 'is-danger' : ''"
      :message="applicationErrors.shared_with ? applicationErrors.shared_with : ''"
    >
      <b-taginput
        v-model="application.shared_with"
        autocomplete
        :data="filteredUsers"
        field="full_name"
        type="is-primary"
        @typing="getFilteredUsers"
      />
    </b-field>
    <b-button
      type="is-primary"
      expanded
      @click="updateApplication"
    >
      Save
    </b-button>

    <hr>
    <div class="is-bold my-4">
      <strong>Danger area</strong>
    </div>
    <b-button
      type="is-danger"
      @click="confirmDelete()"
    >
      Delete application
    </b-button>
    <ConfirmDeleteModal
      v-model="isDeleteAppModalVisible"
      :entity-name="application.name"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Confirm Delete Application"
      aria-modal
      @delete="deleteApplication()"
    />
  </div>
</template>

<script >
import { useBuefy } from '@/hooks/buefy';
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { deleteApplicationService, fetchApplicationByIdService, updateApplicationService } from '@/services/application-service/applicationRequests';
import { fetchUserByIdService, fetchUsersService } from '@/services/user-service/userRequests';
import { defineAsyncComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import * as Yup from 'yup'; //-- child components --//

const ConfirmDeleteModal = defineAsyncComponent(() => import('@/modules/core/components/ConfirmDeleteModal.vue')); //-- composable hooks --//

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  const buefy = useBuefy(); //-- application logic --//

  const application = reactive({
    name: '',
    description: '',
    shared_with: []
  }); // -- Application Validation --//

  const ApplicationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.').min(2, 'Name must be at least 2 characters long.'),
    shared_with: Yup.array().required('Collaborator is required.').min(1, 'Please select at least 1 collaborator.')
  });
  const applicationErrors = reactive({
    name: '',
    shared_with: ''
  });

  const validateApplication = async field => {
    try {
      await ApplicationSchema.validateAt(field, application);
      applicationErrors[field] = '';
    } catch (err) {
      applicationErrors[err.path] = err.message;
    }
  };

  const isDeleteAppModalVisible = ref(false);

  const confirmDelete = () => {
    isDeleteAppModalVisible.value = true;
  };

  const deleteApplication = async () => {
    try {
      await deleteApplicationService(route.params.appId);
      isDeleteAppModalVisible.value = false;
      buefy.toast.open('Application deleted!');
      router.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  const updateApplication = async () => {
    try {
      await ApplicationSchema.validate(application, {
        abortEarly: false
      });
    } catch (err) {
      err.inner.reverse().forEach(error => {
        applicationErrors[error.path] = error.message;
      });
      return;
    }

    try {
      await updateApplicationService(route.params.appId, application);
    } catch (err) {
      console.error(err);
    }
  }; //-- users logic --//


  const fetchedUsers = ref([]);
  const filteredUsers = ref([]);

  const getFilteredUsers = text => {
    filteredUsers.value = fetchedUsers.value.filter(option => `${option.first_name} ${option.last_name}`.toLowerCase().indexOf(text.toLowerCase()) >= 0);
  };

  const addFullName = userData => {
    userData.full_name = `${userData.first_name} ${userData.last_name}`;
    return userData;
  }; //-- settings logic --//


  const initSettings = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchApplicationByIdService(route.params.appId);
      application.name = data.name;
      application.description = data.description;
      const sharedWithUserPromises = data.shared_with.map(userId => fetchUserByIdService(userId));
      const [usersResponse, ...sharedWithUsersResponses] = await Promise.all([fetchUsersService(), ...sharedWithUserPromises]);
      fetchedUsers.value = usersResponse.data.data.map(user => addFullName(user));
      application.shared_with = sharedWithUsersResponses.map(res => addFullName(res.data.data));
    } catch (err) {
      console.error(err);
    }
  };

  watch(async () => {
    try {
      await ApplicationSchema.validateAt('shared_with', application);
      applicationErrors['shared_with'] = '';
    } catch (err) {
      applicationErrors[err.path] = err.message;
    }
  });
  onMounted(() => {
    initSettings();
  });
  return {
    application,
    applicationErrors,
    validateApplication,
    isDeleteAppModalVisible,
    confirmDelete,
    deleteApplication,
    updateApplication,
    filteredUsers,
    getFilteredUsers
  };
};

__sfc_main.components = Object.assign({
  ConfirmDeleteModal
}, __sfc_main.components);
export default __sfc_main;
</script>
